.language-imgs {
  height: 1.2rem;
}

.language-txt {
  font-family: "Plus Jakarta Sans", sans-serif;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 26px !important;
  color: #121212 !important;
  top: 1px !important;
}

.home-header_img {
  width: 62%;
  height: 500px;
  top: 40px;
  border-radius: 7px;
}

.description-ban {
  font-size: 18px;
  font-weight: 400;
  color: #ffffff;
  opacity: 0.5;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
}

.title-ban {
  font-size: 42px;
  font-weight: 700;
  color: #fff;
  text-align: center;
  margin-bottom: 10px;
}
@media only screen and (max-width: 1024px) {
  .home-header_img {
    width: 75%;
    height: 800px;
  }
}
@media only screen and (max-width: 768px) {
  .home-header_img {
    width: 75%;
    height: 600px;
  }
  .title-ban {
    font-size: 38px;
  }
  .description-ban {
    font-size: 16px;
    line-height: 25px;
  }
}
@media only screen and (max-width: 480px) {
  .home-header_img {
    width: 95%;
    height: 450px;
    border-radius: 7px;
  }
  .title-ban {
    font-size: 32px;
  }
  .description-ban {
    font-size: 15px;
    line-height: 20px;
  }
}
