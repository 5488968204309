.form-application_info .application-info_title {
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 800;
  line-height: 120%;
  text-align: left;
  margin-top: 12px;
  margin-bottom: 28px;
  text-transform: uppercase;
}
.form-application_info .application-info_title img {
  width: 26px;
  height: 26px;
  margin-right: 8px;
}
.form-application_info .out input {
  font-size: 16px;
  font-weight: 400;
  color: #888888;
}
