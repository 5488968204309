.user-info_section .wd-form-login {
  max-width: 100% !important;
}
.user-info_section .user-info_wrapper,
.user-info_section .user-info_forms {
  padding: 16px;
  border: 1px solid #8888;
  border-radius: 7px;
  box-shadow: 0 0 2px #8888;
}
/*  */
.user-title_info {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.user-title_info img {
  max-width: 160px;
  max-height: 240px;
  padding: 4px;
  border: 1px solid #8888;
  border-radius: 5px;
}
.user-title_info h6 {
  font-size: 18px;
  font-weight: 700;
  line-height: 150%;
  color: #222222;
  margin-top: 6px;
}
.user-title_info p {
  font-size: 16px;
  font-weight: 400;
  color: #222222;
  margin: 0;
}

/* ============== */
.user-subtitle_info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.user-subtitle_info h6 {
  font-size: 17px;
  font-weight: 600;
  color: #222222;
  margin-top: 12px;
  margin-bottom: 6px;
}

.user-subtitle_info ul li {
  font-size: 13px;
  font-weight: 400;
  color: #222222;
  margin-top: 4px;
}
.user-subtitle_info ul li span {
  font-size: 13px;
  font-weight: 700;
  color: #222222;
}
.change-password_navigate {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 8px;
  margin: 24px auto;
  padding: 0 12px;
}
.change-password_navigate a {
  display: inline-block;
  width: 100%;
  font-size: 16px;
  font-weight: 700;
  padding: 8px 12px;
  background-color: #14a077;
  color: #ffffff;
  text-align: center;
  border-radius: 7px;
  border: 1px solid #14a077;
}
.change-password_navigate a:hover {
  background-color: #ffffff;
  color: #14a077;
}

@media only screen and (max-width: 992px) {
  .user-info_section .user-info_forms {
    padding: 16px 52px;
    margin-top: 12px;
  }
  .user-subtitle_info {
    align-items: center;
  }
  .change-password_navigate {
    flex-direction: row;
  }
}
@media only screen and (max-width: 600px) {
  .user-info_section .user-info_forms {
    padding: 16px 32px;
  }
  .user-subtitle_info {
    align-items: flex-start;
  }
}
@media only screen and (max-width: 481px) {
  .user-info_section .user-info_forms {
    padding: 16px;
  }
}
